import React from 'react';
import { Helmet } from 'react-helmet';

const TemporaryNotice = () => {
  return (
    <>
      <Helmet>
        <title>Ticket Sale Announcement</title>
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', textAlign: 'center' }}>
        <h2>Продажа билетов на мероприятие начнется 27 августа 2024 года в 15:00.</h2>
      </div>
    </>
  );
};

export default TemporaryNotice;
