import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#e28743' }}>
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/events">Events</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/organizations">Organizations</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/contact">Contact</MenuItem>
            </Menu>
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, fontFamily: 'Roboto, sans-serif', textDecoration: 'none' }}
              component={Link}
              to="/"
              color="inherit" // Добавляем цвет, чтобы текст остался белым
            >
              Hambax
            </Typography>
          </>
        ) : (
          <>
           <Typography
              variant="h6"
              sx={{ flexGrow: 1, fontFamily: 'Roboto, sans-serif', textDecoration: 'none' }}
              component={Link}
              to="/"
              color="inherit" // Добавляем цвет, чтобы текст остался белым
            >
              Hambax
            </Typography>
            <Button color="inherit" component={Link} to="/" sx={{ fontFamily: 'Roboto, sans-serif' }}>Home</Button>
            <Button color="inherit" component={Link} to="/events" sx={{ fontFamily: 'Roboto, sans-serif' }}>Events</Button>
            <Button color="inherit" component={Link} to="/organizations" sx={{ fontFamily: 'Roboto, sans-serif' }}>Organizations</Button>
            <Button color="inherit" component={Link} to="/contact" sx={{ fontFamily: 'Roboto, sans-serif' }}>Contact</Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
