import React from 'react';
import { Card, CardContent, Typography, Paper, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const OrganizationCard = ({ organization }) => {
  if (!organization) {
    return null;
  }

  const avatarUrl = organization?.avatar
    ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(organization.avatar)}`
    : '/path/to/default-avatar.png'; // Замените путь на ваш путь к аватару по умолчанию

  const backgroundUrl = organization?.backgroundImage
    ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(organization.backgroundImage)}`
    : '/path/to/default-background.png'; // Замените путь на ваш путь к фону по умолчанию

  return (
    <Paper sx={{ marginBottom: '20px' }}>
      <Link to={`/organization/${organization.organizationSlug}`} style={{ textDecoration: 'none' }}>
        <Card>
          <div
            style={{
              position: 'relative',
              height: '140px',
              backgroundImage: `url(${backgroundUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <img
              src={avatarUrl}
              alt="Organization Avatar"
              style={{
                position: 'absolute',
                bottom: '-30px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                border: '3px solid white',
              }}
            />
          </div>
          <CardContent style={{ textAlign: 'center', marginTop: '40px' }}>
            <Typography variant="h6" component="div">
              {organization.organizationName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {organization.address}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {organization.phone}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Paper>
  );
};

export default OrganizationCard;
