import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardMedia, Grid, CircularProgress, Box, Container } from '@mui/material';
import defaultBackground from '../assets/defaultBackground.png';
import defaultAvatar from '../assets/defaultAvatar.png';

const RecommendedOrganizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/organization`);
                const data = await response.json();
                setOrganizations(data.slice(0, 3)); // Показываем не более 3 организаций
            } catch (error) {
                console.error('Error fetching organizations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizations();
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container sx={{ mt: 4, mb: 8 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
                Recommended Organizations
            </Typography>
            <Grid container spacing={4}>
                {organizations.map((org) => {
                    const backgroundImage = org.backgroundImage
                        ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(org.backgroundImage)}`
                        : defaultBackground;

                    const avatarImage = org.avatar
                        ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(org.avatar)}`
                        : defaultAvatar;

                    return (
                        <Grid item xs={12} sm={4} key={org._id}>
                            <Link to={`/organization/${org.organizationSlug}`} style={{ textDecoration: 'none' }}>
                                <Card sx={{ height: '300px', position: 'relative' }}>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            height: '150px',
                                            backgroundImage: `url(${backgroundImage})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    >
                                        <img
                                            src={avatarImage}
                                            alt="Organization Avatar"
                                            style={{
                                                position: 'absolute',
                                                bottom: '-30px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                width: '60px',
                                                height: '60px',
                                                borderRadius: '50%',
                                                border: '3px solid white',
                                            }}
                                        />
                                    </Box>
                                    <CardContent sx={{ textAlign: 'center', mt: 3 }}>
                                        <Typography variant="h6">{org.organizationName}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {org.address}
                                        </Typography>
                                        <Typography variant="body2" color="text.primary">
                                            {org.phone}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default RecommendedOrganizations;
