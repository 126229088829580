import React from 'react';
import { IconButton } from '@mui/material';
import { Facebook, Instagram, Twitter, LinkedIn, YouTube } from '@mui/icons-material';

const SocialLinks = ({ socialLinks }) => {
  const openSocialLink = (url) => {
    if (url) {
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      {socialLinks?.facebook && (
        <IconButton onClick={() => openSocialLink(socialLinks.facebook)} aria-label="Facebook">
          <Facebook style={{ color: '#3b5998' }} />
        </IconButton>
      )}
      {socialLinks?.instagram && (
        <IconButton onClick={() => openSocialLink(socialLinks.instagram)} aria-label="Instagram">
          <Instagram style={{ color: '#E1306C' }} />
        </IconButton>
      )}
      {socialLinks?.twitter && (
        <IconButton onClick={() => openSocialLink(socialLinks.twitter)} aria-label="Twitter">
          <Twitter style={{ color: '#1DA1F2' }} />
        </IconButton>
      )}
      {socialLinks?.linkedin && (
        <IconButton onClick={() => openSocialLink(socialLinks.linkedin)} aria-label="LinkedIn">
          <LinkedIn style={{ color: '#0077b5' }} />
        </IconButton>
      )}
      {socialLinks?.youtube && (
        <IconButton onClick={() => openSocialLink(socialLinks.youtube)} aria-label="YouTube">
          <YouTube style={{ color: '#FF0000' }} />
        </IconButton>
      )}
    </div>
  );
};

export default SocialLinks;
