import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardMedia, Grid, CircularProgress, Container, Paper } from '@mui/material';
import Navbar from '../components/Navbar';
import defaultBackground from '../assets/defaultBackground.png';
import defaultAvatar from '../assets/defaultAvatar.png';

const OrganizationsList = () => {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/organization`);
                const data = await response.json();
                setOrganizations(data);
            } catch (error) {
                console.error('Error fetching organizations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizations();
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div>
            <Container disableGutters maxWidth="xl" style={{ padding: '0 20px', marginTop: '20px' }}>
                <Paper style={{ padding: '20px'}}>
                    <Typography variant="h5" gutterBottom>
                        All Organizations
                    </Typography>
                    <Grid container spacing={4}>
                        {organizations.map((org) => {
                            const backgroundImage = org.backgroundImage
                                ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(org.backgroundImage)}`
                                : defaultBackground;

                            const avatarImage = org.avatar
                                ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(org.avatar)}`
                                : defaultAvatar;

                            return (
                                <Grid item key={org._id} xs={12} sm={6} md={4}>
                                    <Link to={`/organization/${org.organizationSlug}`} style={{ textDecoration: 'none' }}>
                                        <Card style={{ height: '300px', position: 'relative' }}>
                                            {/* Верхняя часть карточки */}
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    height: '150px',
                                                    backgroundImage: `url(${backgroundImage})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                }}
                                            >
                                                <img
                                                    src={avatarImage}
                                                    alt="Organization Avatar"
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-30px',
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                        width: '60px',
                                                        height: '60px',
                                                        borderRadius: '50%',
                                                        border: '3px solid white',
                                                    }}
                                                />
                                            </div>

                                            {/* Нижняя часть карточки */}
                                            <CardContent style={{ textAlign: 'center', marginTop: '40px' }}>
                                                <Typography variant="h6" component="div">
                                                    {org.organizationName}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {org.address}
                                                </Typography>
                                                <Typography variant="body2" color="text.primary">
                                                    {org.phone}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
};

export default OrganizationsList;
