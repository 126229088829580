import { createTheme } from '@mui/material/styles';

// Базовая настройка темы
const theme = createTheme({
  palette: {
    primary: {
      main: '#e28743', // Основной цвет (оранжевый в вашем случае)
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#1976d2', // Вторичный цвет (например, синий)
    },
    background: {
      default: '#f5f5f5', // Цвет фона
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Основной шрифт
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none', // Отключаем верхний регистр для кнопок
    },
  },
  shape: {
    borderRadius: 8, // Радиус скругления по умолчанию
  },
});

export default theme;
