import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import featuresBg from '../assets/features-bg.png';
import EventIcon from '@mui/icons-material/Event'; // Замените на ваши иконки
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import DiscountIcon from '@mui/icons-material/LocalOffer';
import CommunityIcon from '@mui/icons-material/People';

const EventFeatures = () => {
  const features = [
    {
      title: 'Diverse Event Options',
      description: 'Discover concerts, festivals, workshops, and more across your city.',
      icon: <EventIcon fontSize="large" />,
    },
    {
      title: 'Easy Ticket Purchase',
      description: 'Buy tickets quickly and securely with our platform.',
      icon: <TicketIcon fontSize="large" />,
    },
    {
      title: 'Exclusive Discounts',
      description: 'Get special offers and discounts only available to our community members.',
      icon: <DiscountIcon fontSize="large" />,
    },
    {
      title: 'Join a Community',
      description: 'Be part of a vibrant community of event-goers and enthusiasts.',
      icon: <CommunityIcon fontSize="large" />,
    },
  ];

  return (
    <Box
      sx={{
        width: '100vw', // Фон на всю ширину экрана
        backgroundImage: `url(${featuresBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        padding: '60px 0',
        mb: 4,
        overflow: 'hidden',
      }}
    >
      {/* Оверлей с оранжевым цветом */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          backgroundColor: '#e28743',
          opacity: 0.5,
          zIndex: 1,
        }}
      />

      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 20px',
        }}
      >
        <Grid container spacing={4} sx={{ textAlign: 'center' }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Paper
                sx={{
                  height: '280px',
                  padding: '20px',
                  backgroundColor: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                  borderRadius: '30px',
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '102px',
                    height: '102px',
                    aspectRatio: '1 / 1', // Фиксируем пропорции круга
                    backgroundColor: '#e28743',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 3,
                    border: '10px solid #edf3fe',
                    transition: 'all 0.4s ease',
                    '&:hover svg': {
                      transform: 'scaleX(-1)', // Эффект зеркального отображения
                    },
                  }}
                >
                  <Box
                    sx={{
                      color: '#fff',
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: 'transform 0.4s',
                    }}
                  >
                    {feature.icon}
                  </Box>
                </Box>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                  {feature.title}
                </Typography>
                <Typography variant="body1">{feature.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default EventFeatures;
