import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Box, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import EventCard from '../components/EventCard';
import { Link } from 'react-router-dom';
import homeBg from '../assets/home_bg.jpg';
import EventCountdown from '../components/EventCountdown';
import EventFeatures from '../components/EventFeatures';
import RecommendedOrganizations from '../components/RecommendedOrganizations';
import HowItWorks from '../components/HowItWorks';

const Home = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events/upcoming`);
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          width: '100%', // Ширина на весь экран
          height: '500px',
          backgroundImage: `url(${homeBg})`, // Ваш путь к изображению
          backgroundSize: 'cover',
          backgroundPosition: 'center', // Выравнивание изображения по центру
          backgroundRepeat: 'no-repeat', // Убираем повтор изображения
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          mb: 4,
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Discover the Best Events in Your City
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Join our community and never miss out on the best events around you.
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/events"
          sx={{ backgroundColor: '#e28743', color: '#fff', padding: '10px 30px', fontSize: '18px' }}
        >
          Explore Events
        </Button>
      </Box>

      {/* Основное содержимое */}
      <Container>
        {/* Карусель предстоящих событий */}
        <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
          Upcoming Events
        </Typography>
        <Carousel
          navButtonsAlwaysVisible={true}
          indicators={false}
          autoPlay={true}
          interval={5000}
          sx={{ mb: 4 }}
        >
          {events.map((event) => (
            <EventCard key={event._id} event={event} />
          ))}
        </Carousel>
      </Container>

      {/* Event Features Section */}
      <EventFeatures />

      <Container>
        {/* Таймер секции */}
        <EventCountdown />

        {/* How It Works Section */}
        <HowItWorks />

        {/* Рекомендуемые организации */}
        <RecommendedOrganizations />

      </Container>
    </>
  );
};

export default Home;
