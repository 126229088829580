import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Проверяем, дал ли пользователь согласие ранее
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    // Сохраняем согласие в localStorage
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  const handleDecline = () => {
    // Сохраняем отказ в localStorage (можете доработать логику для отказа)
    localStorage.setItem('cookieConsent', 'false');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null; // Не отображаем баннер, если пользователь уже дал согласие
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#2c3e50',
        color: '#fff',
        padding: '16px',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1">
        We use cookies to improve your experience. By using our site, you agree to our <a href="/privacy-policy" style={{ color: '#00aaff', textDecoration: 'underline' }}>Privacy Policy</a>.
      </Typography>
      <Box>
        <Button onClick={handleAccept} variant="contained" sx={{ marginRight: '8px', backgroundColor: '#00aaff' }}>
          Accept
        </Button>
        <Button onClick={handleDecline} variant="outlined" sx={{ color: '#fff', borderColor: '#fff' }}>
          Decline
        </Button>
      </Box>
    </Box>
  );
};

export default CookieConsent;
