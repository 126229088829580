import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
      <Container maxWidth="md" sx={{ padding: '20px 0' }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated: [Today's Date]
        </Typography>
        <Box sx={{ marginBottom: '20px' }}>
          <Typography variant="body1" paragraph>
            At Hambax, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website and use our services.
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We collect the following types of information:
          </Typography>
          <Typography variant="body1" component="ul" paragraph>
            <li><strong>Cookies:</strong> We use cookies to improve your browsing experience and for analytics purposes. Cookies help us understand how you interact with our website and enhance its functionality.</li>
            <li><strong>Email Address:</strong> We collect your email address when you purchase a ticket on our platform.</li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            The information we collect is used for the following purposes:
          </Typography>
          <Typography variant="body1" component="ul" paragraph>
            <li><strong>Email Address:</strong> We use your email address to process your ticket purchases, send you order confirmations, and keep you informed about any updates related to your event.</li>
            <li><strong>Cookies:</strong> Cookies are used for website functionality and performance analytics, helping us improve our platform and provide a better user experience.</li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Sharing Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We do not sell or share your personal information with third parties, except for the following:
          </Typography>
          <Typography variant="body1" component="ul" paragraph>
            <li><strong>Payment Processing:</strong> When you purchase a ticket, you will be redirected to the Stripe payment gateway to complete your transaction. Hambax does not store or have access to your payment information; it is securely handled by Stripe in accordance with their privacy policy.</li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain your data for no longer than 1 year from the date of collection. After this period, your information is securely deleted from our systems unless required by law.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have the following rights regarding your personal data:
          </Typography>
          <Typography variant="body1" component="ul" paragraph>
            <li><strong>Right to Access:</strong> You can request a copy of the personal data we hold about you.</li>
            <li><strong>Right to Deletion:</strong> You can request that we delete your personal data, subject to certain legal exceptions.</li>
            <li><strong>Right to Withdraw Consent:</strong> You can opt-out of any marketing communications at any time by following the unsubscribe instructions in our emails.</li>
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Cookies and Analytics
          </Typography>
          <Typography variant="body1" paragraph>
            Our website uses cookies to collect information about your browsing habits. You can manage your cookie preferences through your browser settings. Disabling cookies may affect the functionality of our website.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest update will be indicated at the top of the policy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography variant="body1">
            <Link href="mailto:info@hambax.com" underline="none" sx={{ color: '#00aaff' }}>
              info@hambax.com
            </Link>
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
