import React from 'react';
import { Grid, Box, Typography, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const steps = [
  {
    title: 'Discover Events',
    description: 'Find the best events happening around you.',
  },
  {
    title: 'Get Tickets',
    description: 'Purchase tickets easily and securely.',
  },
  {
    title: 'Join the Event',
    description: 'Attend the event and have an amazing experience.',
  },
  {
    title: 'Enjoy',
    description: 'Have a memorable time at the event.',
  },
];

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ textAlign: 'center', mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        How It Works
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        direction={isMobile ? 'column' : 'row'}
      >
        {steps.map((step, index) => (
          <Grid item xs={12} sm={5} md={3} key={index}>
            <Paper
              sx={{
                padding: '20px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '16px',
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                {step.title}
              </Typography>
              <Typography variant="body1">{step.description}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorks;
