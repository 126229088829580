import React from 'react';
import EventCard from './EventCard';
import { Grid, Typography } from '@mui/material';

const EventList = ({ events }) => {
  return (
    <Grid container spacing={4}>
      {events.length > 0 ? (
        events.map((event) => (
          <Grid item key={event._id} xs={12}>
            <EventCard event={event} />
          </Grid>
        ))
      ) : (
        <Typography variant="body1" style={{ textAlign: 'center', width: '100%' }}>
          No upcoming events.
        </Typography>
      )}
    </Grid>
  );
};

export default EventList;
