import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import etCounterBg from '../assets/et-counter-bg.jpg';

const EventCountdown = () => {
  const calculateTimeLeft = () => {
    const eventDate = new Date('2024-12-31T00:00:00'); // Задайте дату и время события здесь
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        padding: '40px',
        borderRadius: '20px',
        backgroundImage: `url(${etCounterBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow: '0px 4px 50px rgba(18, 96, 254, 0.2)',
        color: '#fff',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        mb: 4,
      }}
    >
      <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Count Every Second Until Event.
        </Typography>
        <Button
          variant="outlined"
          sx={{
            color: '#fff',
            borderColor: '#fff',
            padding: '10px 20px',
            ':hover': {
              backgroundColor: '#fff',
              color: '#1976d2',
            },
          }}
        >
          Buy Ticket
        </Button>
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {Object.keys(timeLeft).length > 0 ? (
          <>
            <Grid item xs={6} md={3} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{timeLeft.days || '0'}</Typography>
              <Typography>Days</Typography>
            </Grid>
            <Grid item xs={6} md={3} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{timeLeft.hours || '0'}</Typography>
              <Typography>Hours</Typography>
            </Grid>
            <Grid item xs={6} md={3} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{timeLeft.minutes || '0'}</Typography>
              <Typography>Minutes</Typography>
            </Grid>
            <Grid item xs={6} md={3} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{timeLeft.seconds || '0'}</Typography>
              <Typography>Seconds</Typography>
            </Grid>
          </>
        ) : (
          <Typography variant="h6">Event Expired</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default EventCountdown;
