import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import Home from './screens/Home';
import Events from './screens/Events';
import OrganizationPage from './screens/OrganizationPage';
import OrganizationsList from './screens/OrganizationsList';
import EventDetails from './screens/EventDetails';
import PrivacyPolicy from './screens/PrivacyPolicy';
import theme from './theme';
import TemporaryNotice from './screens/TemporaryNotice';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Router>
          <Navbar />
          <div style={{ flex: '1' }}>
            <Routes>
              <Route path="/" element={<Navigate to="/organization/vpiska" />} />
              <Route path="/vpiska" element={<Navigate to="/organization/vpiska" />} />
              <Route path="/home" element={
                <>
                  <Helmet>
                    <title>Home</title>
                  </Helmet>
                  <Home />
                </>
              } />
              <Route path="/events" element={
                <>
                  <Helmet>
                    <title>Events</title>
                  </Helmet>
                  <Events />
                </>
              } />
              <Route path="/events/:eventId" element={
                <>
                  <Helmet>
                    <title>Event Details</title>
                  </Helmet>
                  <EventDetails />
                </>
              } />
              <Route path="/organization/:slug" element={
                <>
                  <Helmet>
                    <title>Organization Details</title>
                  </Helmet>
                  <OrganizationPage />
                </>
              } />
              <Route path="/organizations" element={
                <>
                  <Helmet>
                    <title>Organizations</title>
                  </Helmet>
                  <OrganizationsList />
                </>
              } />
              <Route path="/privacy-policy" element={
                <>
                  <Helmet>
                    <title>Privacy Policy</title>
                  </Helmet>
                  <PrivacyPolicy />
                </>
              } />
            </Routes>
          </div>
          <Footer />
          <CookieConsent />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
