import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#2c3e50', color: '#fff', padding: '40px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Логотип и описание */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Hambax
            </Typography>
            <Typography variant="body2" color="inherit">
              Hambax is your all-in-one platform for managing every aspect of event planning. From organizing events to ticket sales, we’ve got everything you need.
            </Typography>
          </Grid>

          {/* Навигационные ссылки */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              <Link href="/" color="inherit" underline="none" display="block" sx={{ marginBottom: '8px' }}>
                Home
              </Link>
              <Link href="/events" color="inherit" underline="none" display="block" sx={{ marginBottom: '8px' }}>
                Events
              </Link>
              {/* <Link href="/about" color="inherit" underline="none" display="block" sx={{ marginBottom: '8px' }}>
                About Us
              </Link>
              <Link href="/contact" color="inherit" underline="none" display="block" sx={{ marginBottom: '8px' }}>
                Contact
              </Link> */}
              <Link href="/privacy-policy" color="inherit" underline="none" display="block">
                Privacy Policy
              </Link>
            </Box>
          </Grid>

          {/* Контактная информация */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Box>
              {/* <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <LocationOnIcon sx={{ marginRight: '8px' }} />
                <Typography variant="body2">123 Event Street, Cologne, Germany</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <PhoneIcon sx={{ marginRight: '8px' }} />
                <Typography variant="body2">+49 123 456 789</Typography>
              </Box> */}
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <EmailIcon sx={{ marginRight: '8px' }} />
                <Typography variant="body2">info@hambax.com</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Социальные сети */}
        {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
          <IconButton href="https://facebook.com" target="_blank" sx={{ color: '#fff' }}>
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://instagram.com" target="_blank" sx={{ color: '#fff' }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://twitter.com" target="_blank" sx={{ color: '#fff' }}>
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://linkedin.com" target="_blank" sx={{ color: '#fff' }}>
            <LinkedInIcon />
          </IconButton>
        </Box> */}

        {/* Копирайт */}
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography variant="body2" color="inherit">
            &copy; {new Date().getFullYear()} Hambax. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
