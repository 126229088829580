import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Paper, CircularProgress } from '@mui/material';
import Navbar from '../components/Navbar';
import SocialLinks from '../components/SocialLinks';
import EventList from '../components/EventList';
import defaultBackground from '../assets/defaultBackground.png';
import defaultAvatar from '../assets/defaultAvatar.png';

const OrganizationPage = () => {
  const { slug } = useParams();
  const [organization, setOrganization] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(defaultAvatar);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/organization/slug/${slug}`);
        const data = await response.json();

        setOrganization(data.organization);
        setEvents(data.upcomingEvents);

        if (data.avatar) {
          setAvatar(`${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(data.avatar)}`);
        }
      } catch (error) {
        console.error('Error fetching organization:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [slug]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <CircularProgress />
      </div>
    );
  }

  const backgroundImage = organization?.backgroundImage
    ? `${process.env.REACT_APP_API_URL}/api/image?path=${encodeURIComponent(organization.backgroundImage)}`
    : defaultBackground;

  return (
    <div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '250px',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <img
          src={avatar}
          alt="Organization Avatar"
          style={{
            position: 'absolute',
            bottom: '-50px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            border: '4px solid white',
          }}
        />
      </div>
      <Container sx={{ marginTop: '40px' }}>
        <Container disableGutters maxWidth={false} style={{ marginTop: '40px', padding: '0 10px' }}>
            <Paper style={{ padding: '20px', marginBottom: '30px' }}>
            <Typography variant="h4" gutterBottom>
                {organization.organizationName}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {organization.description}
            </Typography>
            <SocialLinks socialLinks={organization.socialLinks} />
            </Paper>
        </Container>

        <Container disableGutters maxWidth={false} style={{ padding: '0 10px' }}>
            <Paper style={{ padding: '20px' }}>
            <Typography variant="h5" gutterBottom>
                Upcoming Events
            </Typography>
            <EventList events={events} />
            </Paper>
        </Container>
    </Container>
    </div>
  );
};

export default OrganizationPage;
