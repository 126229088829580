import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { CircularProgress, Typography, Container } from '@mui/material';
import EventList from '../components/EventList'; // Импортируем созданный компонент EventList

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events/upcoming`);
        const data = await response.json();
        setEvents(data);
        console.log(response);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Container style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Our Events
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here you can find all upcoming events.
        </Typography>
        <EventList events={events} /> {/* Используем компонент EventList для отображения списка событий */}
      </Container>
    </div>
  );
};

export default Events;
